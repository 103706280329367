import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { MediaFilesDownload } from 'src/views/components/MediaFilesDownload'
import NoContent from 'src/views/components/NoContent'
import PopoverComponent from 'src/views/components/PopoverComponent'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioWorkPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:work')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const { pathname } = useLocation()
  const t = useTranslatable()
  const locale = useLocale()

  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const work = useApi({
    endpoint: Api.getLecturerPortfolioSectionWork,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.workId!,
        },
      }),
      [headers, params.workId, params.sectionId]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works` },
    { page: `${t('portfolio:work')}`, path: pathname },
  ]

  return (
    <Section
      title={t('portfolio:portfolio_work')}
      icon={<Portfolio />}
      breadcrubms={breadcrumbsItems}
      rightElement={<BackButton link={`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works`} />}
    >
      <div className="text-primaryTextColor">
        {work != null ? (
          <>
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_competencies') + `: `}</h2>
              {work.data.competencies !== null
                ? work.data.competencies?.map((competency) => (
                    <div key={competency.id} className="mb-2">
                      <div className="mt-2">
                        {competency.name}
                        <PopoverComponent>
                          <span
                            className="mb-3 flex py-2"
                            dangerouslySetInnerHTML={{ __html: competency.description }}
                          />
                          <div className="mb-6">
                            <div className="mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor">
                              {t('portfolio:criterias')}:
                            </div>
                            {competency.criterias?.map((criteria) => (
                              <div key={criteria.id} className="my-2">
                                <label className="mb-3 text-primaryTextColor">
                                  <div className="my-">
                                    {criteria.name}
                                    <div>
                                      <span
                                        className="p-2"
                                        dangerouslySetInnerHTML={{ __html: criteria.description }}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>
                        </PopoverComponent>
                      </div>
                      {competency.criterias?.map((criteria) => (
                        <div key={criteria.id} className="mb-6 mt-4 rounded-card bg-card px-4 py-1">
                          <div key={criteria.id} className="mb-2.5 mt-3 grid grid-cols-6">
                            <div className="col-span-1">
                              <label>{t('portfolio:criterion')}:</label>
                            </div>
                            <div className="col-span-5 float-left">
                              <div>{criteria.name}</div>
                            </div>
                          </div>
                          {criteria.text && (
                            <div key={criteria.id} className="my-2.5 grid grid-cols-6">
                              <div className="col-span-1">
                                <label>{t('portfolio:description')}:</label>
                              </div>
                              <div className="col-span-5 float-left">
                                <div>{criteria.text}</div>
                              </div>
                            </div>
                          )}
                          {criteria.score?.score || criteria.score?.score == 0 ? (
                            <div key={criteria.id} className="my-2.5 grid grid-cols-6">
                              <div className="col-span-1">
                                <label>{t('common:score')}:</label>
                              </div>
                              <div className="col-span-5 float-left">
                                <div>{criteria.score?.score}</div>
                              </div>
                            </div>
                          ) : null}
                          {work.data?.status === 'STATUS_EVALUATED' && (
                            <div key={criteria.id} className="my-2.5 grid grid-cols-6">
                              <div className="col-span-1">
                                <label>{t('portfolio:work_feedback')}:</label>
                              </div>
                              <div className="col-span-5 float-left">
                                <div> {criteria.score?.feedback}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))
                : null}
            </div>
            {work.data.text ? (
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_description') + `: `}</h2>
                <span dangerouslySetInnerHTML={{ __html: work.data.text }} />
              </div>
            ) : null}

            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('student:student') + `: `}</h2>
              {work.data.student != null ? (
                <div className="text-title">
                  <UserPhoto className="scale-[0.7]" user={work.data.student} />
                  <Link
                    to={`/${locale}/users/${work.data.student?.uid}`}
                    className="ml-2 whitespace-nowrap align-middle !no-underline hover:underline"
                    data-tooltip-id={`${work.data.id}-lecturer`}
                  >
                    <span className="ml-2">{work.data.student?.fullName}</span>
                  </Link>
                </div>
              ) : (
                <span> - </span>
              )}
            </div>
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_status') + `: `}</h2>
              {work.data.status != null ? (
                <div>
                  {work.data.status === 'STATUS_PENDING' && <span>{t('portfolio:status_pending')}</span>}
                  {work.data.status === 'STATUS_EVALUATED' ? <span>{t('portfolio:status_evaluated')}</span> : null}
                  {work.data.status === 'STATUS_REJECTED' && <span>{t('portfolio:status_rejected')}</span>}
                </div>
              ) : (
                <span> - </span>
              )}
            </div>
            {work.data.comment ? (
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:comment') + `: `}</h2>
                <div className="mb-6">{work.data.comment}</div>
              </div>
            ) : null}

            {work.data.mediaFiles !== undefined && work.data.mediaFiles.length > 0 ? (
              <div className="mb-8">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2">
                  {t('file:attached_files') + `: `}
                </h2>
                <div className="text-title">
                  <div className="row-auto my-3">
                    <div className="col-auto mb-2">
                      {work.data.mediaFiles.map((file) => (
                        <div key={file.id} className="flex">
                          <span className="ml-2">
                            <FontAwesomeIcon icon={ProSolidSvgIcons.faDownload} />
                          </span>
                          <MediaFilesDownload
                            mediaFileId={file.id}
                            className="mx-2 text-primaryTextColor"
                            style={{ height: 'fit-content' }}
                          >
                            <div>{file.originalName}</div>
                          </MediaFilesDownload>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <NoContent
            header={t('error:recommendations_not_found')}
            image={Warning}
            subHeader={t('course:for_request_recommendation')}
          />
        )}
        {work.data.manage?.canSubmitScore === true &&
        work.data.status !== 'STATUS_EVALUATED' &&
        work.data.status !== 'STATUS_REJECTED' ? (
          <div className="!mb-9 flex items-center justify-end">
            <Link to="edit">
              <Button variant="blue" className="flex items-center">
                {t('portfolio:evaluation')}
              </Button>
            </Link>
            {params.sectionId !== '4' && (
              <Link to="reject" className="ml-3">
                <Button variant="red" className="flex items-center">
                  {t('portfolio:reject')}
                </Button>
              </Link>
            )}
          </div>
        ) : null}
      </div>
    </Section>
  )
}
